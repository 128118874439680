// side-nav styles
.side-nav {
	ul {
		@extend %list-unstyled;
		padding-right: 30px;
	}

	li {
		margin: 0 0 10px;
	}

	a {
		color: $placeholder;
		text-decoration: none;
		line-height: 1.2;
		display: inline-block;

		&:hover {
			text-decoration: underline;
		}
	}

	.active {
		a {
			color: $orange;
		}
	}
}
