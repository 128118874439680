// site-footer styles
.site-footer {
	background-color: $darkgrey;
	color: #fff;
	padding: 45px 0 30px;

	&__top, &__bottom {
		@extend %clearfix;

		a {
			color: #fff;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	ul {
		@extend %list-unstyled;
		@extend %clearfix;
	}

	h3 {
		color: $orange;
		font-size: calc-em(24px);
		text-transform: uppercase;
		font-weight: 400;
		margin: 0 0 12px;
	}

	.hotline, .quote {
		position: relative;

		p { position: relative; margin: 0; padding-left: 34px; margin: 0 0 5px; }

		i {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
		}
	}

	// .hotline {
	// 	font-size: calc-em(20px);

	// 	i {
	// 		font-size: calc-em(20px, 20px);
	// 	}
	// }

	// .quote {
	// 	font-size: calc-em(20px);

	// 	i {
	// 		font-size: calc-em(20px, 20px);
	// 	}
	// }

	.social {
		margin-top: 15px;

		li {
			float: left;
			margin: 0 12px 0 0;
		}

		a {
			display: inline-block;
			width: 36px;
			@include vertical-middle(36px);
			border-radius: 50%;
			color: $orange;
			text-decoration: none;
			border: 1px solid $orange;
			text-align: center;

			&:hover {
				color: darken($orange, 10%);
				border: 1px solid darken($orange, 10%);
			}
		}

		i {
			@include vertical-middle-child;
			font-size: calc-em(18px);
		}
	}

	.qr-code {
		margin-top: 20px;

		img {
			max-width: 100px;
		}
	}

	.copyright {
		color: $orange;
		font-size: calc-em(14px);
		margin: 0;
	}

	&__top {
		padding: 0 0 30px;

		li {
			margin: 0 0 15px;
		}
	}

	&__bottom {
		text-align: center;

		ul {
			display: inline-block;
		}

		li {
			float: left;
			padding: 0 0 0 8px;

			&:first-child {
				padding: 0;

				a {
					&:before {
						display: none;
					}
				}
			}
		}

		a {
			display: inline-block;
			font-size: calc-em(14px);

			&:before {
				content: '';
				position: relative;
				display: inline-block;
				width: 1px;
				height: 13px;
				background-color: #fff;
				margin-right: 8px;
				top: 4px;
				vertical-align: top;
			}
		}
	}
}


@media only screen and (max-width: $bpDesktop - 1){
	.site-footer {
		text-align: center;

		&__top {
			padding: 0 0 15px;
		}

		.hotline, .quote {
			p { display: inline-block; margin: 0 0 10px; }
		}

		.col-sm-3 {
			margin-bottom: 20px;
		}

		.social {
			margin-bottom: 15px;
			li { float: none; display: inline-block; margin: 0 6px; }
		}

		&__bottom li { float: none; display: inline-block; }
	}
}
