// get-a-quote styles
.quote-tab {
	position: fixed;
	top: 10%;
	right: -70px;
	z-index: 7;
	transition: right 0.2s;
	transform-origin: 100% 100%;
	transform: rotate(-90deg);
	color: #fff;
	background-color: $blue;
	border-color: $lightblueborder;
	display: inline-block;
	margin-bottom: 0;
	font-weight: 700;
	font-size: calc-em(24px);
	line-height: 1;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	border: 1px solid transparent;
	white-space: nowrap;
	padding: 20px 30px;
	text-transform: uppercase;
	text-decoration: none;

	&:link, &:visited, &:active {
		color: #fff;
	}

	&:hover {
		background-color: $darkerblue;
		border-color: $darkblue;
		color: #fff;
	}

	&.slide-in {
		right: 0;
	}
}

@media print {
	.quote-tab {
		display: none;
	}
}
