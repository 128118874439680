// form styles
.show-hide-form {
	background-color: $offblack;
	text-align: center;
	padding: 20px 0;

	button {
		display: inline-block;
		font-size: calc-em(20px);
		color: #fff;
		background-color: $blue;
		border: none;
		padding: 15px 30px;
		margin: 10px 0;
		cursor: pointer;
		font-family: $primaryFont;

		&:hover {
			background-color: lighten($blue, 10%);
		}
	}
}

.quote-form {
	color: #fff;
	max-width: 1280px;
	margin: auto;
	position: relative;

	.form-container {
		position: relative;
		padding: 0 25px 20px;
		background-color: $offblack;
	}

	h1 {
		font-size: calc-em(18px, 14px);
		text-align: center;
		color: #fff;
		margin: 0 0 13px;
	}

	label {
		color: $orange;
	}

	div[data-sf-role="form-container"] {
		input, select {
			width: 100%;
			font-size: calc-em(13px);
		}

		.dropdown-copy, .datepicker-custom {
			button {
				width: 100%;
			}

			i {
				right: 2px;
				transform: translate(0, -50%);
			}
		}

		.datepicker-custom {
			position: relative;

			i {
				position: absolute;
				color: $orange;
				background-color: #fff;
				top: auto;
				bottom: 3px;
				transform: translate(0, 0);
				padding: 8px;
				background-color: transparent;
			}
		}

		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 3px;
		}

		&::-webkit-scrollbar-thumb {
		    border-radius: 3px;
		    background-color: #8b8f96;
		}

		> div > div > div {
			text-align: right;
			font-size: calc-em(14px);
		}
	}
}

@media only screen and (max-width: $bpTablet - 1) {
	.quote-form {
		padding-top: 50px;

		.form-container {
			display: none;
		}

		label {
			font-size: calc-em(13px);
		}

		div[data-sf-role="form-container"] {
			input, select {
				font-size: calc-em(13px);
			}
		}
	}
}

@media only screen and (min-width: $bpTablet), print {
	.quote-form {
		padding: 0 20px;

		.form-container {
			width: 42%;
			padding: 25px 30px;
			background-color: $formblack;
			position: absolute;
			bottom: calc(100% + 50px);
			right: 20px;
			z-index: 2;
		}

		h1 {
			font-size: calc-em(16px, 14px);
			text-align: left;
		}

		div[data-sf-role="form-container"] {
			height: calc(100vh - 207px);
			max-height: 408px;
			overflow-y: scroll;

			input, select {
				width: calc(100% - 15px);
			}

			.dropdown-copy, .datepicker-custom {
				i {
					right: 17px;
				}
			}

			&::-webkit-scrollbar {
				-webkit-appearance: none;
			}

			&::-webkit-scrollbar:vertical {
				width: 3px;
			}

			&::-webkit-scrollbar-thumb {
			    border-radius: 3px;
			    background-color: #8b8f96;
			}

			> div > div > div {
				padding-right: 15px;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.quote-form {
		.form-container {
			width: 36%;
		}

		div[data-sf-role="form-container"] {
			height: calc(100vh - 289px);
			max-height: 408px;
		}

		h1 {
			font-size: calc-em(22px, 14px);
		}
	}
}

@media only screen and (min-width: $bpLgDesktop) {
	.quote-form {
		padding: 0 120px;

		.form-container {
			right: 120px;
		}
	}
}
