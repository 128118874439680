// site-header styles
.site-header {
	z-index: 10;

	&__top, &__bottom {
		@extend %clearfix;
	}

	ul {
		@extend %list-unstyled;
		@extend %clearfix;
	}

	&__button {
		height: 60px;
		background-color: $offblack;
		border: none;
		position: relative;
		padding: 9px 10px;
		cursor: pointer;

		.line {
			position: relative;
			width: 22px;
			height: 2px;
			background-color: #fff;

			&.line1 {
				top: -4px;
			}

			&.line3 {
				top: 4px;
			}
		}
	}

	.site-logo, .country {
		float: left;
	}

	.site-logo {
		@extend %clearfix;

		img {
			float: left;
			display: block;
		}

		img.desktop-only {
			height: 60px;
		}

		.logo {
			margin-top: 8px;
			height: 44px;
		}
	}

	.country {
		img {
			margin-top: 13px;
			margin-left: 20px;
			height: 34px;
		}
	}

	&__button, .site-nav__primary, .hotline, .language-selector {
		float: right;
	}

	.hotline {
		display: block;
		height: 60px;
		background-color: $blue;
		color: #fff;

		p {
			position: relative;
			font-size: calc-em(16px);
			margin: 0;
			padding: 20px 25px;

			&:before {
				@extend %icomoon;
				content: "\e903";
				padding-right: 10px;
				color: #fff;
				font-size: calc-em(18px, 23px);
			}
		}

		&:hover {
			background-color: lighten($blue, 10%);
		}
	}

	&__bottom {
		background-color: $orange;
	}

	a {
		display: inline-block;
		text-decoration: none;
		color: #000;
		font-family: $primaryFont;
	}

	.language-selector {
		ul {
			@include vertical-middle(60px);
			overflow: hidden;
			margin: 0 18px;
		}

		li {
			float: left;
			height: 100%;

			&:after {
				content: '|';
				font-size: calc-em(13px);
				color: $placeholder;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			&.active {
				a {
					color: $orange;
				}
			}
		}

		a {
			padding: 22px 4px;
			font-size: calc-em(13px);
			color: $placeholder;

			&:hover {
				color: $orange;
			}
		}
	}

	.site-nav {
		&__primary {
			ul {
				@include vertical-middle(60px);
				overflow: hidden;
			}

			li {
				float: left;
				height: 100%;
			}

			a {
				padding: 22px 8px;
				font-size: calc-em(13px);
				font-weight: bold;

				&:hover {
					background-color: $hovergrey;
				}
			}
		}

		&__secondary {
			> ul > li {
				position: relative;
				text-align: center;
				padding: 0;

				&.has-children {
					> a {
						&:before {
							@extend %icomoon;
  							content: "\e902";
  							padding-right: 10px;
  							color: #fff;
						}
					}
				}

				> a {
					width: 100%;
					height: 100%;
					font-size: calc-em(16px);
					font-weight: 700;
					padding: 27px 0;
					text-transform: uppercase;
					background-color: $orange;
				}
			}
		}
	}

	.language-selector--mobile {
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.site-header {
		&__button {
			height: 46px;

			.line {
				width: 18px;

				&.line1 {
					top: -3px;
				}

				&.line3 {
					top: 3px;
				}
			}
		}

		.language-selector {
			ul {
				height: 46px;
				margin: 0 10px;

				a {
					padding: 16px 1px;
					font-size: calc-em(11px);
				}
			}
		}

		.hotline {
			height: 46px;

			p {
				font-size: calc-em(14px);
				padding: 13px 6px;

				&:before {
					padding-right: 5px;
					font-size: calc-em(13px, 16px);
				}
			}
		}

		.site-logo {

			.logo {
				height: 30px;
			}
		}
	}
}

@media only screen and (max-width: $bpDesktop - 1){
	.site-header {
		position: fixed;
		top: 0;
		width: 100%;
		background-color: #fff;

		&__top {
			border-bottom: 5px solid $orange;
		}

		&__bottom {
			display: none;
			position: absolute;
			width: 100%;

			.container { padding: 0; }
		}

		.site-logo {
			margin-left: 15px;
		}

		.language-selector--mobile {
			background-color: #fff;
			padding: 10px 15px;
			border-top: 1px solid $hovergrey;

			// li {
			// 	background-color: #fff;
			// 	border-top: 1px solid $hovergrey;
			// }

			// a {
			// 	display: block;
			// 	width: 100%;
			// 	padding: 10px 15px;
			// 	background-color: #fff;
			// 	font-family: $primaryFont;
			// 	font-weight: 700;
			// }

			li {
				float: left;
				height: 100%;

				&:after {
					content: '|';
					font-size: calc-em(13px);
					color: $placeholder;
				}

				&:last-child {
					&:after {
						display: none;
					}
				}

				&.active {
					a {
						color: $orange;
					}
				}
			}

			a {
				padding: 0 4px;
				font-size: calc-em(13px);
				color: #000;
				font-weight: bold;
			}
		}

		.site-nav {
			&__secondary {
				.open {
					> i {
						&:before {
							transform: rotate(-180deg);
						}
					}
				}

				> ul > li {
					position: relative;

					&:last-child {
						> a {
							border-bottom: none;
						}
					}

					> a {
						text-align: left;
						padding: 12px 17px;
						font-size: calc-em(14px);
						font-family: $primaryFont;
						font-weight: 700;
						background-color: $menuorange;
						border-bottom: 1px solid $orangeborder;
					}

					&.has-children {
						> a {
							&:before {
								display: none;
							}
						}

						i {
  							position: absolute;
  							background-color: $darkorange;
  							height: 42px;
  							padding: 16px;
  							top: 0;
  							right: 0;
  							color: #fff;
  							cursor: pointer;

  							&:before {
  								display: inline-block;
  								transition: all 0.4s;
  							}
						}
					}
				}

				.children {
					display: none;
					background-color: darken($menuorange, 10%);

					li {
						position: relative;

						&:last-child {
							a {
								border-bottom: none;
							}
						}
					}

					a {
						text-align: left;
						display: inline-block;
						width: 100%;
						padding: 5px 20px;
						font-size: calc-em(13px);
						border-bottom: 1px solid $orangeborder;
					}

					&__lvl2 {
						.lvl3-controller {
  							height: 26px;
  							padding: 7px 16px;
						}
					}
				}
			}

			&__mobile {
				li {
					background-color: #fff;
					border-bottom: 1px solid $hovergrey;

					&:last-child {
						border-bottom: none;
					}
				}

				a {
					display: block;
					width: 100%;
					padding: 10px 15px;
					background-color: #fff;
					font-family: $primaryFont;
					font-weight: 700;
					font-size: calc-em(14px);
				}
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) and (max-width: 1159px){
	.site-header {
		&__bottom {
			.container {
				padding: 0 20px;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop), print {
	.site-header {
		&__top {
			position: fixed;
			z-index: 100;
			top: 0;
			width: 100%;
			background-color: #fff;
		}

		&__bottom {
			padding-top: 60px;
		}

		.site-nav {
			&__secondary {
				> ul > li {
					&:hover {
						.children__lvl2 {
							visibility: visible;
							opacity: 1;
						}
					}

					&:last-child {
						.children__lvl3 {
					    	left: auto;
					    	right: 100%;
					    	border-radius: 4px 0 0 4px;
						}
					}
				}

				.children {
					transition: all 0.4s ease;
					visibility: hidden;
					opacity: 0;
					position: absolute;
					top: 100%;
					left: 0;
					text-align: left;
					background-color: rgba(35,36,42,0.8);
					padding: 5px 0;
					border-radius: 0 0 4px 4px;
					z-index: 3;

					li {
						position: relative;
					}

					a {
						display: inline-block;
						width: 100%;
						padding: 3px 20px;
						color: #fff;

						&:hover {
							background-color: $blue;
						}
					}

					&__lvl2 {
						li {
							&:hover {
								.children__lvl3 {
									visibility: visible;
									opacity: 1;
								}
							}
						}
					}

					&__lvl3 {
						position: absolute;
						top: 0;
						left: 100%;
						width: 100%;
						border-radius: 0 4px 4px 0;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $bpLgDesktop){
	.site-header {
		&__bottom {
			padding-top: 70px;
		}

		.site-logo {
			img.desktop-only {height: 70px; }

			.logo { margin-top: 7px; height: auto; }
		}

		.country {
			img {
				margin-top: 18px;
				margin-left: 30px;
			}
		}

		.hotline {
			height: 70px;

			p {
				font-size: calc-em(23px);
				padding: 20px 25px;
			}
		}

		.language-selector {
			ul {
				margin-left: 10px;
				margin-right: 20px;
			}

			a {
				padding: 27px 8px;
				font-size: calc-em(13px);
				color: $placeholder;
			}
		}

		.site-nav {
			&__primary {
				ul {
					@include vertical-middle(70px);
					margin: 0 20px 0 30px;
				}

				a {
					padding: 26px 15px;
					max-height: 70px;
					overflow: hidden;
					font-size: calc-em(14px);
					font-weight: bold;
				}
			}

			&__secondary {
				> ul > li {
					> a {
						font-size: calc-em(18px);
					}
				}
			}
		}
	}
}

@media print {
	.site-header {
		&__top {
			position: static;
		}
		&__bottom {
			padding-top: 0;
		}
	}
	.site-nav__primary {
		width: 100%;

		ul {
			float: right;
		}
	}
	.site-nav__secondary {
		height: 100px;
	}
	.children__lvl2 {
		display: none;
	}
}
