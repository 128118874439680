//Import helpers
@import './_fonts';
@import './_variables';
@import './_mixins';
@import './_helpers';
@import './_icons';
@import './_grids';
@import './fontawesome/font-awesome';

// Normalize Styles
@import 'node_modules/normalize.css/normalize';
@import 'node_modules/pikaday/scss/pikaday';

//Plugin Styles
// @import 'node_modules/slick-carousel/slick/slick.scss';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

// Styling on warning for users on IE7 or below
.browsehappy {
	text-align: center;
	margin: 0;
	background: #fff8e2;
	color: #000;
	padding: 0.5em 0;
}

body {
	font-family: $primaryFont;
	line-height: 1.3;
	background: #fff;
	font-size: 16px;
	min-width: 0 !important;
}

iframe {
	max-width: 100% !important;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
}

img {
	max-width: 100%;
}

a, a:link, a:visited, a:hover, a:active
{
    color: $orange;
    text-decoration: none;
}
a:hover, a:active, a:focus
{
    text-decoration: none;
    color: $orangeborder;
    cursor: pointer;
}

.page-title {
	text-align: center;
	text-transform: uppercase;
	font-size: calc-em(30px);
	margin: 0 0 40px;
	color: $orange;
	font-weight: bold;
}

.layout-standard {
	margin: 40px auto 60px;

	> .sfContentBlock {
		width: 75%;
		padding: 0 10px;
		float: right;
	}

	.share {
		margin-top: 35px;
		text-align: right;

		ul {
			@extend %list-unstyled;
			@extend %clearfix;
			display: inline-block;
		}

		li {
			float: left;
			margin-left: 12px;
		}

		a {
			display: inline-block;
			width: 36px;
			@include vertical-middle(36px);
			border-radius: 50%;
			color: $orange;
			text-decoration: none;
			border: 1px solid $orange;
			text-align: center;

			&:hover {
				color: darken($orange, 10%);
				border: 1px solid darken($orange, 10%);
			}
		}

		i {
			@include vertical-middle-child;
			font-size: calc-em(18px);
		}
	}
}

.layout-listing {
	margin: 50px auto 0;
}

.col-sm-9 {
	.container {
		padding: 0;
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.home {
		.rte {
			text-align: center;
		}
	}

	.layout-standard {
		> .sfContentBlock {
			width: 100%;
		}
	}
}

@media only screen and (min-width: $bpTablet){
	.layout-standard {
		margin: 80px auto 60px;
	}
}

@media only screen and (min-width: $bpDesktop){
	.home {
		// margin-top: 60px;

		.rte {
			max-width: 62.5%;
			padding-right: 80px;

			h1, h2, h3 {
				font-size: calc-em(28px);
				font-weight: 700;
			}

			h4, h5, h6 {
				font-size: calc-em(22px);
				font-weight: 700;
			}
		}
	}
}

// Sitefinity clearfix
[class^="sf"]:not(.sf-fieldWrp), [class*=" sf"]:not(.sf-fieldWrp) {
	@extend %clearfix;
}

// Imported styles
/* --- Expandable list --- */
/* Expandable lists wrapper */
.sfexpandableListWrp
{
}
/* List title */
.sfexpandableListWrp .sflistTitle
{
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: bold;
}
/* Single list wrapper */
.sfexpandableListWrp .sflistList
{
    margin-bottom: 18px;
    list-style-type: none;
    padding: 0;
}
/* List item wrapper */
.sfexpandableListWrp .sflistListItem
{
    margin-bottom: 2px;
}
/* Single list item title */
.sfexpandableListWrp .sflistItemTitle
{
    font-size: 20px;
}
/* List item toggle link */
.sfexpandableListWrp .sflistItemToggleLnk
{
    padding-left: 15px;
}
.sfexpandableListWrp .sflistListItemExpanded.sflistItemToggleLnk
{
    font-weight: bold;
    background-position: 0 -975px;
}
/* Single list item content */
.sfexpandableListWrp .sflistItemContent
{
    padding-top: 3px;
    padding-left: 15px;
    padding-bottom: 15px;
}
/* Single list item content when expanded */
.sfexpandableListWrp .sflistItemContent.sflistItemContentExp
{
}
/* "Expand all" link wrapper */
.sfexpandableListWrp .sflistExpandAllLnkWrp
{
    text-align: right;
    font-size: 11px;
    position: relative;
    z-index: 100;
    margin-bottom: -22px;
}
/* "Expand all" link */
.sfexpandableListWrp .sflistExpandAllLnk
{
}


// Import Modules
// Atoms
@import '../_modules/atoms/get-a-quote/get-a-quote';


// Molecules
@import '../_modules/molecules/side-nav/side-nav';
@import '../_modules/molecules/landing-item/landing-item';
@import '../_modules/molecules/listing-item/listing-item';


// Organisms
@import '../_modules/organisms/site-header/site-header';
@import '../_modules/organisms/site-footer/site-footer';
@import '../_modules/organisms/banner/banner';
@import '../_modules/organisms/rte/rte';
@import '../_modules/organisms/testimonial/testimonial';
@import '../_modules/organisms/form/form';
@import '../_modules/organisms/sf-form/sf-form';
