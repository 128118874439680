// testimonial styles
.testimonials-wrapper {
	position: relative;
	margin: 20px 0 80px;

	.testimonials {
		position: relative;

		a {
			color: $orange;
			text-transform: uppercase;
			font-family: $primaryFont;
			font-style: normal;
			text-decoration: none;
			font-size: calc-em(14px);
			margin-top: 5px;
			display: block;

			&:hover {
				text-decoration: underline;
			}
		}

		.attributed-to {
			margin-top: 10px!important;
			line-height: 20px;

			a {

				&:link {
					color: $lightblue;
				}
				&:visited {
					color: $lightblue;
				}
				&:hover {
					color: #fff ;
				}
				&:active {
					color: #fff ;
				}
			}
		}

		strong {
			font-style: italic;
		}
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.testimonials-wrapper {
		.alpha {
			border-bottom: 1px solid $placeholder;
			margin-bottom: 18px;
		}
	}
}

@media only screen and (min-width: $bpTablet) and (max-width: $bpDesktop - 1){
	.testimonials-wrapper {

		.extension-left {
			position: absolute;
			width: 50%;
			height: 147px;
			background-color: $lightblue;
		}

		.extension-right {
			position: absolute;
			right: 0;
			width: 50%;
			height: 147px;
			background-color: $offblack;
			top: 135px;
		}

		.testimonials {
			background: url(../images/testimonial-banner-768.jpg) no-repeat top center;
			height: 282px;
			position: relative;
			color: #fff;

			a {
				color: $orange;
			}

			.attributed-to {
				margin-top: 10px!important;
				line-height: 20px;
				a {
					&:link {
						color: $lightblue;
					}
					&:visited {
						color: $lightblue;
					}
					&:hover {
						color: $offblack;
					}
					&:active {
						color: $offblack;
					}
				}
			}

			.eight {
				&.alpha {
					position: absolute;
					top: 20px;
					left: 30px;

					h1 {
						margin: 0;
						margin-bottom: 10px;
						font-weight: bold;
						font-style: italic;
						font-size: calc-em(22px);
						color: $lightblue2;
					}

					p {
						font-style: italic;
						font-size: calc-em(14px);
						margin: 0;
						color: $lightblue2;
					}
				}

				&.omega {
					position: absolute;
					top: 160px;
					right: 30px;

					h1 {
						margin: 0;
						margin-bottom: 10px;
						font-weight: bold;
						font-style: italic;
						font-size: calc-em(22px);
						color: #fff;
					}

					p {
						font-style: italic;
						font-size: calc-em(14px);
						margin: 0;
						color: #fff;
					}

					strong {
						color: #fff;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop){
	.testimonials-wrapper {
		position: relative;
		margin: 60px 0;

		.extension-left {
			position: absolute;
			width: 50%;
			height: 147px;
			background-color: $lightblue;
		}

		.extension-right {
			position: absolute;
			right: 0;
			width: 50%;
			height: 147px;
			background-color: $offblack;
			top: 35px;
		}

		.testimonials {
			background: url(../images/testimonial-banner.jpg) no-repeat top center;
			height: 182px;
			position: relative;
			color: #fff;

			a {
				color: $orange;
				text-transform: uppercase;
				font-family: $primaryFont;
				font-style: normal;
				text-decoration: none;
				font-size: calc-em(14px);
				margin-top: 5px;
				display: block;
			}

			.attributed-to {
				margin-top: 10px !important;
				line-height: 20px;

				a {

					&:link {
						color: $lightblue;
					}
					&:visited {
						color: $lightblue;
					}
					&:hover {
						color: #fff ;
					}
					&:active {
						color: #fff ;
					}
				}
			}

			.eight {
				&.alpha {
					position: absolute;
					top: 10px;
					left: 30px;
					width: 430px;
					line-height: 25px;

					h1 {
						margin: 0;
						margin-bottom: 10px;
						font-weight: bold;
						font-style: italic;
						font-size: calc-em(22px);
						color: $lightblue2;
					}

					p {
						font-style: italic;
						font-size: calc-em(14px);
						margin: 0;
						color: $lightblue2;
					}
				}

				&.omega {
					position: absolute;
					top: 46px;
					right: 30px;
					width: 430px;
					line-height: 25px;

					h1 {
						margin: 0;
						margin-bottom: 10px;
						font-weight: bold;
						font-style: italic;
						font-size: calc-em(22px);
						color: #fff;
					}

					p {
						font-style: italic;
						font-size: calc-em(14px);
						margin: 0;
						color: #fff;
					}

					strong {
						color: #fff;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: $bpLgDesktop){
	.testimonials-wrapper {
		.testimonials {
			.eight {
				&.alpha {
					left: 130px;
				}

				&.omega {
					right: 130px;
				}
			}
		}
	}
}
