// listing-item styles
.listing-widget {
	@extend %clearfix;
	margin: 0 0 40px;
}

.listing-container {
	@extend %clearfix;
}

.listing-item {
	margin: 0 0 30px;

	&__image {
		@include aspect-ratio(2, 1, 100%);

		img {
			@include image-cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__text {
		h3 {
			font-size: calc-em(24px);
			margin: 22px 0 0;
			font-family: $primaryFont;
			font-weight: 400;
			line-height: 1.1;

			a {
				color: $offblack;
				text-decoration: none;

				&:hover {
					color: $orangeborder;
					text-decoration: underline;
				}
			}
		}

		p {
			margin: 0 0 11px;
		}

		.post-date {
			color: $blue;
			margin: 5px 0;
		}

		.label {
			margin: 0 0 11px;
			display: block;
			text-align: right;
			font-weight: $primaryFont;
			font-weight: 700;
			text-transform: uppercase;
			color: $orange;
			text-decoration: none;

			&:hover {
				color: $orangeborder;
				text-decoration: underline;
			}
		}
	}
}

.listing-load-more {
	text-align: center;
}

.load-more {
	font-family: $primaryFont;
	font-weight: 700;
	border: 1px solid $blueborder;
	display: inline-block;
	text-align: center;
    vertical-align: middle;
    padding: 10px 20px;
    color: #fff;
    background-color: $darkblue;
    text-decoration: none;
    cursor: pointer;

    &:hover {
    	background-color: $darkerblue;
    	border: 1px solid $darkblue;
    }
}

@media only screen and (min-width: $bpTablet){
	.listing-widget {
		margin: 30px auto 60px;
	}
}

@media print {
	.listing-widget {
		page-break-inside: avoid;
	}

	.listing-item {
		page-break-inside: avoid;
	}
}
