// sf-form styles
label {
	display: block;
	margin: 0 0 5px;
	font-size: calc-em(15px);
}

.form-group, .sf-fieldWrp {
	position: relative;
	margin: 0 0 8px;
}

input, select {
	border: 1px solid $placeholder;
	font-size: calc-em(15px);
	padding: 10px 15px;
	color: $placeholder;
	background-color: #fff;
	display: block;
	max-width: 100%;
	width: 350px;

	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: $placeholder;
		font-weight: 400;
	}
	&::-moz-placeholder { /* Firefox 19+ */
		color: $placeholder;
		font-weight: 400;
	}
	&:-ms-input-placeholder { /* IE 10+ */
		color: $placeholder;
		font-weight: 400;
	}
	&:-moz-placeholder { /* Firefox 18- */
		color: $placeholder;
		font-weight: 400;
	}
}

input {
	color: $offblack;
}

select {
	position: absolute;
	bottom: 0;
	opacity: 0;
}

.dropdown-copy {
	position: relative;
	margin: 0 0 8px;

	button {
		border: 1px solid $placeholder;
		font-size: calc-em(15px);
		padding: 10px 15px;
		margin: 0 0 8px;
		color: $placeholder;
		background-color: #fff;
		display: block;
		max-width: 100%;
		width: 350px;
		text-align: left;
	}

	i {
		position: absolute;
		color: $orange;
		background-color: #fff;
		padding: 9px 10px;
		top: 50%;
		right: 17px;
		transform: translate(0, -50%);
	}
}

button.sf-SubmitButton,
.btn.btn-primary.sf-fieldWrp button {
	color: #fff;
	background-color: $blue;
	border: none;
	padding: 8px 37px;
	margin: 10px 0;
	cursor: pointer;
	font-family: $primaryFont;

	&:hover {
		background-color: lighten($blue, 10%);
	}
}

.required {
	label {
		&:before {
			content: '*';
		}
	}
}
