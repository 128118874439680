[class^="col-"], [class*=" col-"] {
	float: left;
	padding: 0 10px;
	width: 100%;
}

%container {
	position: relative;
	margin: 0 auto;
	max-width: 1280px;
	padding: 0 20px;
}

.container {
	@extend %container;
	@extend %clearfix;
}

.row {
	@extend %clearfix;
	margin: 0 -10px;
}

.right { float: right; }
.left { float: left; }

@media only screen and (max-width: $bpTablet - 1) {
	@for $i from 1 through 12 {
		.col-xs-#{$i} {
			width: (100% / 12 * $i);
			padding: 0 10px;
		}

		.offset-xs-#{$i} {
			margin-left: (100% / 12 * $i);
		}

	}

	.offset-xs-0 {
		margin-left: 0;
	}
}

@media only screen and (min-width: $bpTablet), print {
	@for $i from 1 through 12 {
		.col-sm-#{$i} {
			width: (100% / 12 * $i);
			padding: 0 10px;
		}

		.offset-sm-#{$i} {
			margin-left: (100% / 12 * $i);
		}

	}

	.offset-sm-0 {
		margin-left: 0;
	}
}

@media only screen and (min-width: $bpDesktop), print {
	@for $i from 1 through 12 {
		.col-md-#{$i} {
			width: (100% / 12 * $i);
			padding: 0 10px;
		}

		.offset-md-#{$i} {
			margin-left: (100% / 12 * $i);
		}

	}

	.offset-md-0 {
		margin-left: 0;
	}
}

@media only screen and (min-width: $bpLgDesktop) {
	@for $i from 1 through 12 {
		.col-lg-#{$i} {
			width: (100% / 12 * $i);
			padding: 0 10px;
		}

		.offset-lg-#{$i} {
			margin-left: (100% / 12 * $i);
		}

	}

	.offset-lg-0 {
		margin-left: 0;
	}

	.container {
		padding: 0 120px;
	}
}
