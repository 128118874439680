@font-face {
	font-family: 'icomoon';
	src:  url('/assets/fonts/icomoon.eot?lumlu6');
	src:  url('/assets/fonts/icomoon.eot?lumlu6#iefix') format('embedded-opentype'),
  url('/assets/fonts/icomoon.ttf?lumlu6') format('truetype'),
  url('/assets/fonts/icomoon.woff?lumlu6') format('woff'),
  url('/assets/fonts/icomoon.svg?lumlu6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  vertical-align: initial;


	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  &:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: initial;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

%icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: initial;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wechat:before {
  content: "\e90a";
}

.icon-youku:before {
  content: "\e909";
}

.icon-envelope:before {
  content: "\e900";
}

.icon-google:before {
  content: "\e901";
}

.icon-angle-down:before {
  content: "\e902";
}

.icon-phone:before {
  content: "\e903";
}

.icon-youtube-play:before {
  content: "\e904";
}

.icon-linkedin:before {
  content: "\e905";
}

.icon-twitter:before {
  content: "\e906";
}

.icon-facebook:before {
  content: "\e907";
}

.icon-truck:before {
  content: "\e908";
}

.icon-weibo:before {
  content: "\ea9a";
}
