// landing-item styles
.landing-widget {
	@extend %clearfix;
	margin: 40px 0;
	text-align: center;

	h1 {
	 	text-align: center;
	 	text-transform: uppercase;
	 	font-size: calc-em(30px);
	 	margin: 0 0 11px;
	 	color: $orange;
	}

	> p {
		margin: 0 0 11px;
	}
}

.landing-item {
	text-align: center;
	margin: 20px 0;

	.home & {
		margin: 30px 0;
	}

	&__image {
		&:before {
		    content: '';
		    display: inline-block;
		    height: 100%;
		    width: 0;
		    vertical-align: middle;
		}

		a {
			display: inline-block;
			position: relative;
			vertical-align: middle;
		}
	}

	i {
		font-size: calc-em(80px);
		color: $orange;
	}

	h3 {
		font-size: calc-em(24px);
		margin: 22px 0 11px;
		text-transform: uppercase;
		font-family: $primaryFont;
		font-weight: 400;

		a {
			color: $offblack;
			text-decoration: none;

			&:hover {
				color: $orangeborder;
				text-decoration: underline;
			}
		}
	}

	p {
		margin: 0 0 11px;
	}

	h4 {
		font-size: calc-em(18px);
		margin: 11px 0;

		a {
			color: $orange;
			text-decoration: none;

			&:hover {
				color: $orangeborder;
				text-decoration: underline;
			}
		}
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.landing-item {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

@media only screen and (min-width: $bpTablet){
	.landing-widget {
		margin: 30px auto 60px;

		.home & {
			margin: 80px 0 30px;
		}
	}

	.landing-item {
		width: 30%;
		float: none;
		vertical-align: top;
		display: inline-block;
	}
}

@media print {
	.landing-widget {
		page-break-inside: avoid;
	}

	.landing-item {
		page-break-inside: avoid;
		padding-bottom: 50px;
	}
}
