// rte styles
.rte, .sfContentBlock {
	> * {
		&:first-child {
			margin-top: 0;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		color: $orange;
		font-family: $primaryFont;
		font-weight: bold;
	}

	h1 {
		font-size: calc-em(26px);
	}

	h2 {
		font-size: calc-em(24px);
	}

	h3 {
		font-size: calc-em(22px);
	}

	h4 {
		font-size: calc-em(20px);
	}

	h5 {
		font-size: calc-em(18px);
	}

	h6 {
		font-size: calc-em(16px);
	}

	a {
		text-decoration: none;
		color: $orange;

		&:hover {
			text-decoration: underline;
		}
	}

	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #ccc;
		margin: 1em 0;
		padding: 0;
	}

	table {
		border-collapse: collapse;

		th {
			background-color: $orange;
			color: #fff;
		}

		th, td {
			padding: 10px 20px;
			border: 1px solid $hovergrey;
		}
	}
}

@media only screen and (max-width: $bpTablet - 1){
	.rte, .sfContentBlock {
		font-size: 87.5%;
	}
}
