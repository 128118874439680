//Media query breakpoints
$bpMobile: 320px;
$bpLgMobile: 420px;
$bpTablet: 768px;
$bpDesktop: 1024px;
$bpLgDesktop: 1280px;

//font settings
$base-fontsize: 16px;
$mediumFont: 'DIN Medium', Arial, sans-serif;
// $primaryFont: 'DIN', Arial, sans-serif;
$primaryFont: 'Noto Sans', sans-serif;

/* COLOURS */
$darkgrey: #2e3037;
$placeholder: #999999;
$orange: #f38034;
$darkorange: #e97a32;
$menuorange: #f28133;
$orangeborder: #c16729;
$blue: #407dca;
$darkblue: #27548c;
$darkerblue: #2f64a8;
$lightblueborder: #3470bc;
$blueborder: #193558;
$hovergrey: #f2f2f2;
$offblack: #191919;
$lightblue: #b9c7e4;
$lightblue2: #4a82cc;
$formblack: rgba(35, 36, 42, 0.8);;
