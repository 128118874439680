// banner styles
@keyframes bounce {
	0% {
	  transform: translateY(0);
	}

	50% {
	  transform: translateY(5px);
	}

	to {
	  transform: translateY(0);
	}
}

@-webkit-keyframes bounce {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(5px);
	}

	to {
		transform: translateY(0);
	}
}

.page-banner {
	position: relative;

	img {
		@include image-cover;
	}

	.overlay {
		width: 100%;
		height: 100%;
		content: "";
		display: block;
		position: absolute;
		z-index: 1;
		bottom: 0;
		background: url('/assets/images/gradient-bckground.png');
		background-repeat: repeat-x;
		background-position: bottom;
		opacity: .8;
	}
}

.direction-arrow {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	z-index: 1;
	left: 50%;
	bottom: 25px;
	transform: translate(-50%, 0);
	text-align: center;
	color: #fff;
	transition: all 0.3s;

	&.active {
		visibility: visible;
		opacity: 1;
	}

	p {
		color: #fff;
		margin: 0;
		line-height: 1;
	}

	i {
		display: inline-block;
		font-size: calc-em(40px);
		animation: bounce 1.5s ease-in-out infinite;
	}
}

@media only screen and (max-width: $bpDesktop - 1) {
	.page-banner {
		padding-top: 65px;
		height: 100vh;
		max-height: 615px;
	}
}

@media only screen and (min-width: $bpDesktop) {
	.page-banner {
    	height: calc(100vh - 147px);
    	max-height: 550px;
	}
}


